export const date = "Friday, November 19, 2021 11:00:00";

const menu_array = [{ title: "Speakers", href: "Speakers" }, { title: "Schedule", href: "conference" }, { title: "About", href: "about" }, { title:"Partners", href:"Partners"}, { title:"Testimonial", href:"Testimonial"}, { title:"Contacts", href:"Contacts"}];

export const abds_data = {
  nav: {
    title_bold: "Abds title ",
    title: "more abds title",
    day: "19",
    date_short: "nov",
    date_long: "ember",
    city_short: "wash",
    city_long: "ington",
    city: "d.c.",
    desc1: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum in, praesentium ea odio, soluta expedita iusto iure libero exercitationem doloribus hic asperiores dolores amet sint, ab cum sunt ullam quis.",
    desc_orange: "Ipsum in, praesentium ea odio, ",
    desc3: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    button: "Register Now ",
    menu: menu_array,
  },
  countdown: {

  },
  about: {
    
  },
  testimony:{

  },// testimony
  contact:{

  },// contact
  footer:{

  },
}// landing_data

export const form_info = {
  
}