var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Footer -->\r\n<footer class=\"main-footer dark\">\r\n  <div class=\"container\">\r\n    <div class=\"row\">\r\n      <div class=\"col-md-4 mb-30\">\r\n        <div class=\"item fotcont\">\r\n          <div class=\"fothead\">\r\n            <h6>Phone</h6>\r\n          </div>\r\n          <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":10,"column":13},"end":{"line":10,"column":22}}}) : helper)))
    + "</p>\r\n        </div>\r\n      </div>\r\n      <div class=\"col-md-4 mb-30\">\r\n        <div class=\"item fotcont\">\r\n          <div class=\"fothead\">\r\n            <h6>Email</h6>\r\n          </div>\r\n          <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":22}}}) : helper)))
    + "</p>\r\n        </div>\r\n      </div>\r\n      <div class=\"col-md-4 mb-30\">\r\n        <div class=\"item fotcont\">\r\n          <div class=\"fothead\">\r\n            <h6>"
    + alias4(((helper = (helper = lookupProperty(helpers,"address_label") || (depth0 != null ? lookupProperty(depth0,"address_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address_label","hash":{},"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":33}}}) : helper)))
    + "</h6>\r\n          </div>\r\n          <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"address") || (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":26,"column":13},"end":{"line":26,"column":24}}}) : helper)))
    + "</p>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"sub-footer\">\r\n    <div class=\"container\">\r\n      <div class=\"row\">\r\n        <div class=\"col-md-4\">\r\n          <div class=\"text-left\">\r\n            <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"copyright") || (depth0 != null ? lookupProperty(depth0,"copyright") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"copyright","hash":{},"data":data,"loc":{"start":{"line":36,"column":15},"end":{"line":36,"column":28}}}) : helper)))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-md-4 abot\">\r\n          <div class=\"social-icon\"> <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"social") : depth0)) != null ? lookupProperty(stack1,"facebook") : stack1), depth0))
    + "\"><i class=\"ti-facebook\"></i></a> <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"social") : depth0)) != null ? lookupProperty(stack1,"twitter") : stack1), depth0))
    + "\"><i\r\n                class=\"ti-twitter\"></i></a> <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"social") : depth0)) != null ? lookupProperty(stack1,"instagram") : stack1), depth0))
    + "\"><i class=\"ti-instagram\"></i></a> <a\r\n              href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"social") : depth0)) != null ? lookupProperty(stack1,"pinterest") : stack1), depth0))
    + "\"><i class=\"ti-pinterest\"></i></a> </div>\r\n        </div>\r\n        <div class=\"col-md-4\">\r\n          <p class=\"right\"><a href=\"#\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"terms_conditions") || (depth0 != null ? lookupProperty(depth0,"terms_conditions") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"terms_conditions","hash":{},"data":data,"loc":{"start":{"line":45,"column":39},"end":{"line":45,"column":59}}}) : helper)))
    + "</a></p>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</footer>";
},"useData":true});