const service_array = [
  {
    title: "Infrastructure and Strategic Design",
    description: "IT Infrastructure needs are assessed through network audit, a strategic plan is then created that is shared with the client before the implimentation phase begins.     Whether creating a roadmap from scratch or building from preexisting designs, we are prepared to find that conprehensive solution to fit your needs.",
    // img: "abds/lib/img/icons/icon-1.png",
    img: "abds/lib/img/icons/chess-g.svg",
    url: "architecture.html",
    number: "01",
  },
  {
    title: "System Engineering",
    description: "System Engineering focuses on design, integration and management of complex systems throughout their lifecycle. Always working to maintain a synergy by collective     performance and useful functionality.",
    // img: "abds/lib/img/icons/icon-2.png",
    img: "abds/lib/img/icons/construction-g.svg",
    url: "interior-design.html",
    number: "02",
  },
  {
    title: "CyberSecurity",
    description: "IT Security starts with an assessment of your network environment, running scans to measure and identify any data holes or breaches, creating a detailed report that is     shared with the client to support findings, then work to mitigate any and all vulnerabilities. Uplifting the security assurance posture and the overall cybersecurity readiness.",
    // img: "abds/lib/img/icons/icon-3.png",
    img: "abds/lib/img/icons/fingerprint-g.svg",
    url: "urban-design.html",
    number: "03",
  },
  {
    title: "Cloud Base Services",
    description: "Cloud Services provides IT as a service over the internet or a dedicated network/communication line (direct or shared). With payment based usage clients are able to     access a full range of services, applications and development platforms, to servers, storage and virtual desktops delivered all on demand.",
    // img: "abds/lib/img/icons/icon-3.png",
    img: "abds/lib/img/icons/cloud-service-g.svg",
    url: "urban-design.html",
    number: "04",
  },
  {
    title: "Network Design and Engineering",
    description: "Network Design can start with an assessment of a preexisting network or start with planning of the implementation on a newly designed network infrastructure. This design     piece goes hand and hand with a strong cybersecurity posture dictated by a networks different layers and levels of security.",
    // img: "abds/lib/img/icons/icon-3.png",
    img: "abds/lib/img/icons/puzzle-g.svg",
    url: "urban-design.html",
    number: "05",
  },
  {
    title: "Agile/DevOps and Automation",
    description: "Using an Agile based workflow and lifecycle to automate repitive and manual DevOps tasks, streamlining this process, reducing the need for human intervention. Allowing     manual workload to be refocused to other mission critical tasks.",
    // img: "abds/lib/img/icons/icon-3.png",
    img: "abds/lib/img/icons/handshake-g.svg",
    url: "urban-design.html",
    number: "06",
  },
  {
    title: "Administrative Services/Help Desk (Tier 1, 2 and 3)",
    description: "System Administrative services are instrumental in managing infrastructure systems whether needs are software/application, hardware, servers, workstations or printer     based. The goal is to ensure all systems in the environment are running efficiently and effectively.",
    // img: "abds/lib/img/icons/icon-3.png",
    img: "abds/lib/img/icons/smartphone-g.svg",
    url: "urban-design.html",
    number: "07",
  },
];

export const data = {
  title: "Our",
  title_bold: "Solutions",
  services: service_array,
}// data

// const service_array = [
//   {
//     title: "Infrastructure and Strategic Design",
//     description: "Brief description of the service goes in this field.",
//     img: "abds/lib/img/icons/chess-g.svg",
//     url: "architecture.html",
//     number: "01",
//   },
//   {
//     title: "System Engineering",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/construction-g.svg",
//     url: "interior-design.html",
//     number: "02",
//   },
//   {
//     title: "CyberSecurity",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/fingerprint-g.svg",
//     url: "urban-design.html",
//     number: "03",
//   },
//   {
//     title: "Cloud Base Services",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/cloud-service-g.svg",
//     url: "urban-design.html",
//     number: "04",
//   },
//   {
//     title: "Network Design and Engineering",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/puzzle-g.svg",
//     url: "urban-design.html",
//     number: "05",
//   },
//   {
//     title: "Agile / DevOps and Automation",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/handshake-g.svg",
//     url: "urban-design.html",
//     number: "06",
//   },
//   {
//     title: "Administrative Services / Help Desk (Tier 1, 2 and 3)",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/smartphone-g.svg",
//     url: "urban-design.html",
//     number: "07",
//   },
// ];

// export const data = {
//   title: "Our",
//   title_bold: "Services",
//   services: service_array,
// }// data


// const service_array = [
//   {
//     title: "Architecture",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/icon-1.png",
//     url: "architecture.html",
//     number: "01",
//   },
//   {
//     title: "Interior Design",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/icon-2.png",
//     url: "interior-design.html",
//     number: "02",
//   },
//   {
//     title: "Urban Design",
//     description: "Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.",
//     img: "abds/lib/img/icons/icon-3.png",
//     url: "urban-design.html",
//     number: "03",
//   },
// ];

// export const data = {
//   title: "Our",
//   title_bold: "Services",
//   services: service_array,
// }// data