import React from 'react'

const Four04 = () => {

  // let root = document.querySelector('.root');
  // root.style.height = '100%';
        
  let styles = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={styles}>404</div>
  )
}

export default Four04