const data_array = [{ title: "HOME", href: "HOME" }, { title: "ABOUT US", href: "ABOUT US" }, { title: "CAPABILITIES", href: "CAPABILITIES" }, { title: "CAREERS", href: "CAREERS" }, { title: "CONTACT US", href: "CONTACT US" }];

export const data = {
  phone: "+1 703-862-7410",
  email: "infoabds@abinaryds.net",
  address_label: "Our Address",
  address: "Fairfax, VA USA",
  copyright: "© 2021 Bauen. All right reserved.",
  social: {
    facebook: "index.html",
  },
  terms_conditions: "Terms & Conditions",
  menu: data_array,
}// data

// const data_array = [{ title: "Home", href: "Home" }, { title: "About", href: "About" }, { title: "Projects", href: "Projects" }, { title: "Services", href: "Services" }, { title: "Blog", href: "Blog" }, { title: "Contact", href: "Contact" }];

// export const data = {
//   phone: "+1 203-123-0606",
//   email: "architecture@bauen.com",
//   address_label: "Our Address",
//   address: "24 King St, Charleston, SC 29401 USA",
//   copyright: "© 2021 Bauen. All right reserved.",
//   social: {
//     facebook: "index.html",
//   },
//   terms_conditions: "Terms & Conditions",
//   menu: data_array,
// }// data