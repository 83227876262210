const project_array = [
  {
    // category: "Infrastructure and Strategic Design",
    title: "Infrastructure and Strategic Design",
    // img: "https://images.pexels.com/photos/46168/space-telescope-mirror-segments-james-webb-cosmos-46168.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    img: "abds/lib/img/projects/space-telescope-mirror-segments.jpg",
    origin: "https://www.pexels.com/photo/man-standing-on-stainless-steel-filamend-46168/",
    alt: "https://www.pexels.com/photo/high-angle-view-of-a-man-256381/",
    atrib: false,
    url: "cotton-house.html",
  },
  {
    // category: "System Engineering",
    title: "System Engineering",
    // img: "https://images.pexels.com/photos/270360/pexels-photo-270360.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    img: "abds/lib/img/projects/turned-on-computer-monitor-displaying-text.jpg",
    origin: "https://www.pexels.com/photo/turned-on-computer-monitor-displaying-text-270360/",
    atrib: false,
    url: "armada-center.html",
  },
  {
    // category: "CyberSecurity",
    title: "CyberSecurity",
    // img: "https://images.pexels.com/photos/1181317/pexels-photo-1181317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    img: "abds/lib/img/projects/woman-in-black-long-sleeved-top.jpg",
    origin: "https://www.pexels.com/photo/woman-in-black-long-sleeved-top-1181317/",
    atrib: false,
    url: "stonya-villa.html",
  },
  {
    // category: "Cloud Base Services",
    title: "Cloud Base Services",
    // img: "https://images.pexels.com/photos/73910/mars-mars-rover-space-travel-robot-73910.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    img: "abds/lib/img/projects/mars-mars-rover-space-travel-robot.jpg",
    origin: "https://www.pexels.com/photo/gray-and-white-robot-73910/",
    atrib: false,
    url: "prime-hotel.html",
  },
  {
    // category: "Network Design and Engineering",
    title: "Network Design and Engineering",
    // img: "https://images.pexels.com/photos/567452/pexels-photo-567452.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    img: "abds/lib/img/projects/woman-in-black-top.jpg",
    origin: "https://www.pexels.com/photo/woman-in-black-top-567452/",
    atrib: false,
    url: "prime-hotel.html",
  },
  {
    // category: "Agile/DevOps and Automation",
    title: "Agile/DevOps and Automation",
    // img: "https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    img: "abds/lib/img/projects/photo-of-people-doing-handshakes.jpg",
    origin: "https://www.pexels.com/photo/photo-of-people-doing-handshakes-3183197/",
    atrib: false,
    url: "prime-hotel.html",
  },
  {
    // category: "Administrative Services",
    title: "Administrative Services",
    // img: "https://images.pexels.com/photos/1714208/pexels-photo-1714208.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    img: "abds/lib/img/projects/black-flat-screen-computer-monitor.jpg",
    origin: "https://www.pexels.com/photo/black-flat-screen-computer-monitor-1714208/",
    atrib: false,
    url: "prime-hotel.html",
  },
  {
    // category: "Help Desk (Tier 1, 2 and 3)",
    title: "Help Desk (Tier 1, 2 and 3)",
    // img: "https://images.pexels.com/photos/2422293/pexels-photo-2422293.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    img: "abds/lib/img/projects/woman-in-blue-suit-jacket.jpg",
    origin: "https://www.pexels.com/photo/woman-in-blue-suit-jacket-2422293/",
    atrib: false,
    url: "prime-hotel.html",
  },
];

export const data = {
  title: "Our",
  title_bold: "Projects",
  projects: project_array,
}// data

// const project_array = [
//   {
//     category: "Interior",
//     title: "Cotton House",
//     img: "abds/lib/img/projects/1.jpg",
//     url: "cotton-house.html",
//   },
//   {
//     category: "Exterior",
//     title: "Armada Center",
//     img: "abds/lib/img/projects/2.jpg",
//     url: "armada-center.html",
//   },
//   {
//     category: "Urban",
//     title: "Stonya Villa",
//     img: "abds/lib/img/projects/3.jpg",
//     url: "stonya-villa.html",
//   },
//   {
//     category: "Interior",
//     title: "Prime Hotel",
//     img: "abds/lib/img/projects/4.jpg",
//     url: "prime-hotel.html",
//   },
// ];

// export const data = {
//   title: "Our",
//   title_bold: "Projects",
//   projects: project_array,
// }// data