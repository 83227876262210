import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// import Countdown from './components/Countdown';
import Hero from '../components/Hero';
import Lines from '../components/Lines';
import AboutUs from '../components/AboutUs';
import Projects from '../components/Projects';
import Services from '../components/Services';
import Blog from '../components/Blog';
import Contact from '../components/Contact';
import Testimonials from '../components/Testimonials';
import Clients from '../components/Clients';
import Footer from '../components/Footer';


import { AbdsProvider } from '../abdsContext';
const abdsStore = require('../abdsStore').default;

const { abds_data } = require('../data');

const App = () => {

  const store = abdsStore;

  useEffect(() => {
    // effect

    return () => {
      // cleanup
    }
  }, []);

  const addText = function () {
    this.setState({ name: "And some more scratchy" })
  }

  return (
    <>
      <Hero {...{ data: abds_data.nav }} />
      <div className="content-wrapper">
        <Lines {...{ data: abds_data.nav }} />
        <AboutUs {...{ data: abds_data.about }} />
        <Projects {...{ data: abds_data.projects }} />
        <Services {...{ data: abds_data.services }} />
        {/* <Blog {...{ data: abds_data.blog }} /> */}
        <Contact {...{ data: abds_data.contact }} />
        <Testimonials {...{ data: abds_data.testimonials }} />
        <Clients {...{ data: abds_data.clients }} />
        <Footer {...{ data: abds_data.footer }} />
      </div>
    </>
  );
}

export default App;
