import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Faq from '../components/Faqs';


import Footer from '../components/Footer';

const { abds_data } = require('../data');
const { faq_data, backgrounds } = require('../components/Faqs/data');

const Cape = (props) => {

  useEffect(() => {
    // effect

    return () => {
      // cleanup
    }
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Faq {...{ data: faq_data, backgrounds }}/>
        <Footer {...{ data: abds_data.footer }} />
      </div>
    </>
  );
}

export default Cape;
