const blog_array = [{ 
  title: "Modern Architectural Structures 2",
  category: "Architecture 2", 
  date: "20.12.2021" ,
  img: "abds/lib/img/slider/1.jpg",
  url: "post.html",
}, { 
  title: "Modernism in Architecture",
  category: "Interior", 
  date: "18.12.2021" ,
  img: "abds/lib/img/slider/2.jpg",
  url: "post2.html",
}, { 
  title: "Postmodern Architecture",
  category: "Urban", 
  date: "16.12.2021" ,
  img: "abds/lib/img/slider/3.jpg",
  url: "post3.html",
}, { 
  title: "Modern Architecture Buildings",
  category: "Planing", 
  date: "14.12.2021",
  img: "abds/lib/img/slider/4.jpg",
  url: "post4.html",
}];

export const data = {
  title: "Current",//"BAUEN",
  title_bold: "News",
  blog: blog_array,
}// data