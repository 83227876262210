
/**
 * NOTE: section corresponds with data-scroll-index="1"
 * <section id="about" class="about section-padding" data-scroll-index="1">
 */

const pages = [
  { 
    active: true, 
    title: "Home", 
    link: true,
    href: `${location.origin}`, 
    sect: 0, 
    drop: false, 
    submenu: [], 
  },
  { 
    active: true, 
    title: "Capabilities", 
    link: true,
    href: `${location.origin}/capable`, 
    sect: 0, 
    drop: false, 
    submenu: [], 
  }
];

const data_array = [
  { 
    active: true, 
    title: "HOME", 
    href: "#", 
    sect: 0, 
    drop: false, 
    submenu: [], 
  }, { 
    active: true, 
    title: "ABOUT US", 
    href: "#", 
    sect: 1, 
    drop: false, 
    submenu: [], 
  }, { 
    active: true, 
    title: "PROJECTS", 
    href: "#", 
    sect: 2, 
    drop: false, 
    submenu: [], 
  }, { 
    active: true, 
    title: "SOLUTIONS", 
    href: "#", 
    sect: 3, 
    drop: false, 
    submenu: [], 
  }, { 
    active: false, 
    title: "CAREERS", 
    href: "#", 
    sect: 4, 
    drop: false, 
    submenu: [], 
  }, { 
    active: true, 
    title: "CONTACT US", 
    href: "#", 
    sect: 5, 
    drop: false, 
    submenu: [], 
  },{ 
    active: true, 
    title: "PAGES", 
    href: "#", 
    link: true,// goes nowhere - removes sect
    drop: true, 
    submenu: pages, 
  }
];

const back_array = [
  { 
    active: true, 
    title: "BACK", 
    link: true,
    href: `${location.origin}`,
    drop: false, 
    submenu: [], 
  },{ 
    active: true, 
    title: "PAGES", 
    href: "#", 
    link: true,// goes nowhere - removes sect
    drop: true, 
    submenu: pages, 
  }
];

export const data = {
  href: `${location.origin}`,
  logo: "abds/lib/img/a10.svg",
  title: "A BINARY",
  sub: "DATA SOLUTIONS",
  menu: data_array,
}// data

export const back = {
  href: `${location.origin}`,
  logo: "abds/lib/img/a10.svg",
  title: "A BINARY",
  sub: "DATA SOLUTIONS",
  menu: back_array,
}// data