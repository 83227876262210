
export const data = {
  slide1:{
    active: true,
    title: "A Binary Data Solution",
    // background: "abds/lib/img/slider/1.jpg",
    // background: "https://image.shutterstock.com/shutterstock/photos/1108438691/display_1500/stock-photo--d-illustration-color-bytes-of-binary-code-flying-through-a-vortex-background-code-depth-of-field-1108438691.jpg",
    // background: "https://image.shutterstock.com/shutterstock/photos/1493123198/display_1500/stock-photo--d-rendering-of-abstract-highway-path-through-digital-binary-towers-in-city-concept-of-big-data-1493123198.jpg",
    // background:"https://image.shutterstock.com/shutterstock/photos/112994140/display_1500/stock-photo-bright-red-and-orange-sunrise-at-dawn-reflects-washington-monument-in-new-reflecting-pool-by-112994140.jpg",
    background: "abds/lib/img/slider/Monument_MED.png",
    opacity: "2",
    button: {
      active: false,
      text:"",
      href: "https://1.envato.market/mDnXD"
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide2: {
    active: true,
    title: "A Binary Data Solution",
    // background: "abds/lib/img/slider/2.jpg",
    // background: "https://image.shutterstock.com/shutterstock/photos/775592749/display_1500/stock-photo-washington-dc-usa-at-the-tidal-basin-with-washington-monument-in-spring-season-775592749.jpg",
    background: "abds/lib/img/slider/Jeff_Mem_MED.png",
    opacity: "3",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide3: {
    active: true,
    title: "A Binary Data Solution",
    // background: "https://images.pexels.com/photos/2086270/pexels-photo-2086270.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    background: "abds/lib/img/slider/calm-of-body-of-water-in-front-of-mountain.jpg",
    origin: "https://www.pexels.com/photo/calm-of-body-of-water-in-front-of-mountain-2086270/",
    atrib: false,
    // background: "abds/lib/img/slider/Monument_LRG.png",
    opacity: "2",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide4: {
    active: true,
    title: "A Binary Data Solution",
    // background: "https://images.pexels.com/photos/169647/pexels-photo-169647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    background: "abds/lib/img/slider/raised-building-frame.jpg",
    origin: "https://www.pexels.com/photo/raised-building-frame-169647/",
    attrib: false,
    opacity: "4",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide5: {
    active: true,
    title: "A Binary Data Solution",
    // background: "https://images.pexels.com/photos/373912/pexels-photo-373912.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    background: "abds/lib/img/slider/aerial-photo-of-city-commercial-buildings.jpg",
    origin: "https://www.pexels.com/photo/aerial-photo-of-city-commercial-buildings-373912/",
    attrib: false,
    opacity: "2",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide6: {
    active: true,
    title: "A Binary Data Solution",
    // background: "https://images.pexels.com/photos/1632788/pexels-photo-1632788.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=300",
    background: "abds/lib/img/slider/worms-eye-view-of-buildings.jpg",
    origin: "https://www.pexels.com/photo/worm-s-eye-view-of-buildings-1632788/",
    attrib: false,
    opacity: "4",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide7: {
    active: false,
    title: "A Binary Data Solution",
    // background: "https://images.pexels.com/photos/2603464/pexels-photo-2603464.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=200",
    background: "abds/lib/img/slider/photo-of-buildings-during-nighttime.jpg",
    origin: "https://www.pexels.com/photo/photo-of-buildings-during-nighttime-2603464/",
    attrib: false,
    opacity: "2",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide8: {
    active: true,
    title: "A Binary Data Solution",
    // background: "https://image.shutterstock.com/shutterstock/photos/396688780/display_1500/stock-photo-the-sun-is-rising-in-washington-dc-the-daybreak-of-washington-dc-the-rising-sun-beyond-the-us-396688780.jpg",
    background: "abds/lib/img/slider/IWO_MED.png",
    opacity: "2",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide9: {
    active: false,
    title: "A Binary Data Solution",
    background: "https://image.shutterstock.com/shutterstock/photos/1243571683/display_1500/stock-photo-wash-dc-nov-iwo-jima-memorial-in-wash-dc-usa-memorial-dedicated-to-all-personnel-of-1243571683.jpg",
    opacity: "2",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  slide10: {
    active: false,
    title: "A Binary Data Solution",
    background: "abds/lib/img/slider/2.jpg",// 1920 X 1128 conversion calculation 1920 / 640 * 1128
    opacity: "2",
    button: {
      active: false,
      text: "",
      href: "https://1.envato.market/mDnXD",
    },
    line1: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
    line2: "",
  },
  social: [{title: "pinterest", href: ""}, {title: "instagram", href: ""},{title: "twitter", href: ""}, {title: "facebook", href: ""},]
}



