const data_array = [{ title: "HOME", href: "HOME" }, { title: "ABOUT US", href: "ABOUT US" }, { title: "CAPABILITIES", href: "CAPABILITIES" }, { title: "CAREERS", href: "CAREERS" }, { title: "CONTACT US", href: "CONTACT US" }];

export const data = {
  title: "Contact",
  title_bold: "Us",
  text: "Here at A Binary Data Solution we know that COST IS WHAT YOU PAY FOR and VALUE IS WHAT WE ALWAYS DELIVER.",
  NAI: "541990",
  phone: "+1 703-862-7410",
  email: "infoabds@abinaryds.net",
  address: "Fairfax, VA USA",
  placeholders: {
    name: "Full Name",
    email: "Your Email",
    message: "Your Message",
  },
  button: "Send",
  href: "#0",
  menu: data_array,
}// data

// const data_array = [{ title: "Home", href: "Home" }, { title: "About", href: "About" }, { title: "Projects", href: "Projects" }, { title: "Services", href: "Services" }, { title: "Blog", href: "Blog" }, { title: "Contact", href: "Contact" }];

// export const data = {
//   title: "Contact",//"BAUEN",
//   title_bold: "Us",
//   text: "Our firm nisl sodales sit amet sapien idea placerat sodales orcite. Vivamus ne miss rhoncus felis bauen architecture.",
//   VAT: "USA002323065B06",
//   phone: "+1 203-123-0606",
//   email: "architecture@bauen.com",
//   address: "24 King St, Charleston, 29401 USA",
//   placeholders: {
//     name: "Full Name",
//     email: "Your Email",
//     message: "Your Message",
//   },
//   button: "Send",
//   href: "#0",
//   menu: data_array,
// }// data