var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"about-img-2 about-buro\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"caption") || (depth0 != null ? lookupProperty(depth0,"caption") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"caption","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":57}}}) : helper)))
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section id=\"about\" class=\"about section-padding\" data-scroll-index=\"1\">\r\n  <div class=\"container\">\r\n    <div class=\"row\">\r\n      <div class=\"col-md-6 mb-30 animate-box\" data-animate-effect=\"fadeInUp\">\r\n        <h2 class=\"section-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"about") || (depth0 != null ? lookupProperty(depth0,"about") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"about","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":43}}}) : helper)))
    + " <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"company") || (depth0 != null ? lookupProperty(depth0,"company") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company","hash":{},"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":61}}}) : helper)))
    + "</span></h2>\r\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"paragraph1") || (depth0 != null ? lookupProperty(depth0,"paragraph1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paragraph1","hash":{},"data":data,"loc":{"start":{"line":6,"column":11},"end":{"line":6,"column":25}}}) : helper)))
    + "</p>\r\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"paragraph2") || (depth0 != null ? lookupProperty(depth0,"paragraph2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paragraph2","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":25}}}) : helper)))
    + "</p>\r\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"paragraph3") || (depth0 != null ? lookupProperty(depth0,"paragraph3") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paragraph3","hash":{},"data":data,"loc":{"start":{"line":8,"column":11},"end":{"line":8,"column":25}}}) : helper)))
    + "</p>\r\n      </div>\r\n      <div class=\"col-md-6 animate-box\" data-animate-effect=\"fadeInUp\">\r\n        <div class=\"about-img\">\r\n          <div class=\"img\"> <img src="
    + alias4(((helper = (helper = lookupProperty(helpers,"img") || (depth0 != null ? lookupProperty(depth0,"img") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img","hash":{},"data":data,"loc":{"start":{"line":12,"column":37},"end":{"line":12,"column":44}}}) : helper)))
    + " class=\"img-fluid\" alt=\"\"> </div>\r\n          \r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"caption") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":10},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + "          \r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</section>";
},"useData":true});