
export const data = {
  background: "abds/lib/img/running-a-light-of-digital-information.mp4",
  client1: {
    active: true,
    // avatar: "abds/lib/img/team/1.jpg",
    name: "Reggie Turner",
    business: "Systems Engineer",
    comment: `As a 20+ year IT professional, I've had to opportunity to work with many different companies, large and small. I can truthfully say that working with "A Binary Data Solution" was a true pleasure.`,
  },
  client2: {
    active: true,
    // avatar: "abds/lib/img/team/2.jpg",
    name: "Reggie Turner",
    business: "Systems Engineer",
    comment: `Their professionalism and knowledge levels are among the best that I've worked with in my tenured career.  If you are in need of IT support, whether it be for consulting or actual hands-on involvement, I strongly suggest that you consider "A Binary Data Solution" for your IT needs.`
    // comment: "Interior dapibus augue metus the nec feugiat erat hendrerit nec.Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.",
  },
  client3: {
    active: false,
    avatar: "abds/lib/img/team/4.jpg",
    name: "Jesica Smith",
    business: "Alsa Manager",
    comment: "Urban dapibus augue metus the nec feugiat erat hendrerit nec.Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.",
  },
  client4: {
    active: false,
    avatar: "abds/lib/img/team/1.jpg",
    name: "Jason Brown",
    business: "Crowne Plaza Owner",
    comment: "Architect dapibus augue metus the nec feugiat erat hendrerit nec. Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.",
  },
  client5: {
    active: false,
    avatar: "abds/lib/img/team/2.jpg",
    name: "Emily White",
    business: "Armada Owner",
    comment: "Interior dapibus augue metus the nec feugiat erat hendrerit nec.Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.",
  },
}// data

// https://www.pexels.com/video/running-a-light-of-digital-information-3129977/
// https://www.pexels.com/search/videos/technology/

// https://www.pexels.com/video/aerial-view-on-city-during-dawn-9722139/

// https://www.w3schools.com/howto/howto_css_fullscreen_video.asp