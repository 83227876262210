import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import App from './pages/App.js';
// const App = require("./App.js");

import Navbar from './components/Navbar';
import ErrorBoundary from './components/Error';
import Four04 from './components/Four04';

import { AbdsProvider } from './abdsContext';
const abdsStore = require('./abdsStore').default;
const { abds_data } = require('./data');

import { HOME_PATH, CAPE_PATH, FOUR_OH_FOUR } from './paths/'
import Cape from './pages/Cape.js';
import DelayDefer from './components/DeferMe/DelayDefer.js';
require('./style.scss');
require('../../icomoon/style.scss');


Promise.resolve()
.then(() => {return;})
.catch((err) => {
  console.log(err);
  // if this fails - goto home

  // if im here there was a 401 unauthorized status
})
.finally(async (params) => {

  ReactDOM.render(
    <ErrorBoundary>
      <AbdsProvider>
        <Router>
          <Navbar {...{ data: abds_data.nav }} />
          <Switch>
            <Route exact path={HOME_PATH} render={(props) => (<App {...props} />)} />
            <Route exact path={CAPE_PATH} render={(props) => (<Cape {...props} />)} />
            <Route path={FOUR_OH_FOUR} component={Four04} />
          </Switch>
          <DelayDefer />
        </Router>
      </AbdsProvider>
    </ErrorBoundary>,
    document.querySelector(".root")
  )
})