const client_array = [
  {img: "abds/lib/img/clients/1.png", url: "#0"},
  {img: "abds/lib/img/clients/2.png", url: "#0"},
  {img: "abds/lib/img/clients/3.png", url: "#0"},
  {img: "abds/lib/img/clients/4.png", url: "#0"},
  {img: "abds/lib/img/clients/5.png", url: "#0"},
  {img: "abds/lib/img/clients/6.png", url: "#0"},
];

export const data = {
  clients: client_array,
}// data