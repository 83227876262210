import React from 'react';
import AbdsStore from "./abdsStore";

const AbdsContext = React.createContext();

const AbdsProvider = (props) => {
  return (
    <AbdsContext.Provider value={props.store ? props.store : AbdsStore} AbdsStore={props.store ? props.store : AbdsStore} >
      {props.children}
    </AbdsContext.Provider>
  );
}

export {
  AbdsProvider,
  AbdsContext
}