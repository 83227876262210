var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"vid-area\">\r\n            <div class=\"vid-icon\">\r\n              <a class=\"play-button vid\" href=\"https://youtu.be/RziCmLzpFNY\">\r\n                <svg class=\"circle-fill\">\r\n                  <circle cx=\"43\" cy=\"43\" r=\"39\" stroke=\"#fff\" stroke-width=\".5\"></circle>\r\n                </svg>\r\n                <svg class=\"circle-track\">\r\n                  <circle cx=\"43\" cy=\"43\" r=\"39\" stroke=\"none\" stroke-width=\"1\" fill=\"none\"></circle>\r\n                </svg> <span class=\"polygon\">\r\n                  <i class=\"ti-control-play\"></i>\r\n                </span> </a>\r\n            </div>\r\n            <div class=\"cont mt-15 mb-30\">\r\n              <h5>View promo video</h5>\r\n            </div>\r\n          </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"item\"> <span class=\"quote\"><img src=\"abds/lib/img/quot.png\" alt=\"\"></span>\r\n                  <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"comment") : stack1), depth0))
    + "</p>\r\n                  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"comment2") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":18},"end":{"line":52,"column":76}}})) != null ? stack1 : "")
    + "\r\n                  <div class=\"info\">\r\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":54,"column":117}}})) != null ? stack1 : "")
    + "\r\n                    <div class=\"cont\">\r\n                      <h6>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h6> <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"business") : stack1), depth0))
    + "</span>\r\n                    </div>\r\n                  </div>\r\n                </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"comment2") : stack1), depth0))
    + "</p>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"author-img\"> <img src="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + "  alt=\"\"> </div>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"item\"> <span class=\"quote\">\r\n                    <img src=\"abds/lib/img/quot.png\" alt=\"\">\r\n                  </span>\r\n                  <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client2") : depth0)) != null ? lookupProperty(stack1,"comment") : stack1), depth0))
    + "</p>\r\n                  <div class=\"info\">\r\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"client2") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":116}}})) != null ? stack1 : "")
    + "\r\n                    <div class=\"cont\">\r\n                      <h6>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client2") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h6> <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client2") : depth0)) != null ? lookupProperty(stack1,"business") : stack1), depth0))
    + "</span>\r\n                    </div>\r\n                  </div>\r\n                </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"author-img\"> <img src="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"client2") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + " alt=\"\"> </div>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"item\"> <span class=\"quote\">\r\n                    <img src=\"abds/lib/img/quot.png\" alt=\"\">\r\n                  </span>\r\n                  <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client3") : depth0)) != null ? lookupProperty(stack1,"comment") : stack1), depth0))
    + "</p>\r\n                  <div class=\"info\">\r\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"client3") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":20},"end":{"line":82,"column":116}}})) != null ? stack1 : "")
    + "\r\n                    <div class=\"cont\">\r\n                      <h6>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client3") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h6> <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"client3") : depth0)) != null ? lookupProperty(stack1,"business") : stack1), depth0))
    + "</span>\r\n                    </div>\r\n                  </div>\r\n                </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"author-img\"> <img src="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"client3") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + " alt=\"\"> </div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Promo video - Testiominals -->\r\n<section class=\"testimonials section-padding plax\">\r\n  <div class=\"testimonial-wrapper\" data-overlay-dark=\"3\">\r\n    <div class=\"vid-wrapper\">\r\n      <video autoplay muted loop id=\"testimonial-video\" class=\"testimonial-video\">\r\n        <source src="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"background") || (depth0 != null ? lookupProperty(depth0,"background") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"background","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":34}}}) : helper)))
    + " type=\"video/mp4\">\r\n      </video>\r\n    </div>\r\n    <a \r\n      class=\"video-credits\"\r\n      href=\"https://www.pexels.com/video/running-a-light-of-digital-information-3129977/\"\r\n      target=\"_blank\"\r\n    >Video by Pressmaster from Pexels</a>\r\n    <div class=\"container\">\r\n      <div class=\"row\">\r\n        <!-- Promo video -->\r\n        <div class=\"col-md-6\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,false,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":38,"column":17}}})) != null ? stack1 : "")
    + "\r\n        </div>\r\n        <!-- Testiominals -->\r\n        <div class=\"col-md-5 offset-md-1\">\r\n          <div class=\"testimonials-box animate-box\" data-animate-effect=\"fadeInUp\">\r\n            <div class=\"head-box\">\r\n              <h4>What Client's Say?</h4>\r\n            </div>\r\n            <div class=\"owl-carousel owl-theme\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"client1") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":14},"end":{"line":60,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"client2") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":14},"end":{"line":74,"column":21}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"client3") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":14},"end":{"line":88,"column":21}}})) != null ? stack1 : "")
    + "\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</section>";
},"useData":true});