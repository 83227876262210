export const backgrounds = [
  "abds/lib/img/slider/Monument_MED.png",
  "abds/lib/img/slider/Jeff_Mem_MED.png",
  // "abds/lib/img/slider/calm-of-body-of-water-in-front-of-mountain.jpg",
  "abds/lib/img/slider/raised-building-frame.jpg",
  "abds/lib/img/slider/aerial-photo-of-city-commercial-buildings.jpg",
  "abds/lib/img/slider/worms-eye-view-of-buildings.jpg",
  // "abds/lib/img/slider/photo-of-buildings-during-nighttime.jpg",
  "abds/lib/img/slider/IWO_MED.png",
]

export const faq_data = {
  title: "Capability Statement",
  background: "abds/lib/img/slider/Jeff_Mem_MED.png",
  content: [
    {
      active: true,
      mdfy: "active-block",
      home: false,// NOTE: home collapsible will appear open onload
      pre: "1.",
      title: "Core Competencies",
      paragraphs: {
        active: true,
        content: [
          {text: "ABDS exists to provide a solutions driven, trusted partnerships with clients by understanding communicated needs, discovering vulnerabilities, creating and executing a concise plan of action, with customer centered results. We bring extensive Industry knowledge, foresight, and a passion for Technology. Using a collaborative approach while focusing on delivering value.",}
        ]
      },
      bullets: {
        active: true,
        title: "",
        content: [
          {text: "Infrastructure and Strategic Design"},
          {text: "System Engineering"},
          {text: "CyberSecurity"},
          {text: "Cloud Base Services"},
          {text: "Network Design and Engineering"},
          {text: "Agile/DevOps and Automation"},
          {text: "Administrative Services/ Help Desk (Tier 1, 2 and 3)"},
        ]
      }
    },
    {
      active: true,
      mdfy: "",
      pre: "2.",
      title: "Differentiators",
      paragraphs: {
        active: true,
        content: [
          {text: "Maintaining a strategic mix of executive tech and business talent – and the ability to combine various leadership skill and backgrounds which ensure we are meeting customer requirements",},
          {text: "Focusing on secure and stable technical solutions - enforcing compliance with established vendors, strategic partnerships and their resources, which assist with current and cutting-edge technology to enhance company offerings",},
          {text: "Strategic hiring, a process to ensure the best talent - Industry leading staffing consultants with proven backgrounds: in the federal space",},
          {text: "Recurring training- Quarterly staff training to enhance skill set and to stay up to date with the latest technology. Enabling us to meet and/or exceed customers’ expectations.",},
          {text: "Utilization of Journey mapping - Engaging customers with relevant communications at key stages, to help guide customer through the mapping process to ensure synergy and results",},
          {text: "Technical support – we provide dedicated resources to assist with critical support and surge allocations",},
          // {text: "",},
        ]
      },
    },
    {
      active: true,
      mdfy: "",
      pre: "3.",
      title: "Past Performance",
      paragraphs: {
        active: true,
        content: [
          {text: "DoJ, CMAS, Subcontract to GovTact. Contract value available upon request, supported from Aug 12, 2019 - Sep 10, 2021, DevOps infrastructure, Senior Engineers configured and managed virtual and Azure enclaves on both Class and un-Class environments. Contact: Luke Howe, COO, Luke.Howe@GovTact.com, (602)663-2068",},
          {text: "DoS, DS\CTO, Subcontract to CACI. Contract value available upon request, supported from Aug 20, 2016 - Aug 30, 2018, DevOps infrastructure, Team of Network Engineers, SysAdmin and Infrastructure Architects, designed, configured and managed Developmental virtual and Azure enclaves for Dev and Test. Contact: Tino Cascio, Project PM, CCascio@CACI.com, (301)213-0593",},
          {text: "Contracts to include: E&I- Cooperative, NY- State industrial & commercial, NYC - Janitorial,Plumbing and power tools, 8/5/2013 - 4/01/2019, partnered with city, state, higher education and health care entities with solution planning and execution around their industrial & commercial needs, while maintaining contract compliance. Also partnered with selected small businesses on relationship building, solution planning, securing opportunity and execution of desired industrial & commercial solutions, while maintaining contract compliance and rules of engagement. Assisted and trained selected small enterprise on comprehension of local and state, small and minority spend mandates specific to their SMBA certifications. ",},
          // {text: "",},
          // {text: "",},
          // {text: "",},
        ]
      },
    },
    {
      active: false,
      mdfy: "",
      pre: "4.",
      title: "Modern Urban Structures",
      paragraphs: {
        active: true,
        content: [
          {text: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.",},
        ]
      },
    },
    {
      active: false,
      mdfy: "",
      pre: "5.",
      title: "Modern Interior Structures",
      paragraphs: {
        active: true,
        content: [
          {text: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.",},
        ]
      },
    },
  ],
  info: [
    {
      active: true,
      mdfy: "active-block",
      home: false,
      custom: "company",
      pre: "4.",
      title: "Company Data",
      paragraphs: {
        active: true,
        content: [
          {text: "A Binary Data Solution (ABDS) has over 16 years of professional experience in providing Information Technology support services, we have worked within the public and private sector to provide mission critical support for our clients. Since our company’s inception, we emphasize on providing innovative, need-based solutions through detail analysis, bringing cost efficiency and value to our clients.",}
        ]
      },
      bullets: {
        active: true,
        title: "List Specific Codes:",
        content: [
          {text: "DUNS: 081329231"},
          {text: "Socio-economic certifications: SDVOSB, Minority-Owned Business, Small Business: (8a) certs currently Pending"},
          {text: "NAICS: 541990"},
          {text: "CAGE Code: 85PJ8"},
          {text: "Accept Credit and Purchase Cards: Yes"},
        ]
      }
    },
    {
      active: false,
      mdfy: "",
      pre: "2.",
      title: "Differentiators",
      paragraphs: {
        active: true,
        content: [
          {text: "Maintaining a strategic mix of executive tech and business talent – and the ability to combine various leadership skill and backgrounds which ensure we are meeting customer requirements",},
          {text: "Focusing on secure and stable technical solutions - enforcing compliance with established vendors, strategic partnerships and their resources, which assist with current and cutting-edge technology to enhance company offerings",},
          {text: "Strategic hiring, a process to ensure the best talent - Industry leading staffing consultants with proven backgrounds: in the federal space",},
          {text: "Recurring training- Quarterly staff training to enhance skill set and to stay up to date with the latest technology. Enabling us to meet and/or exceed customers’ expectations.",},
          {text: "Utilization of Journey mapping - Engaging customers with relevant communications at key stages, to help guide customer through the mapping process to ensure synergy and results",},
          {text: "Technical support – we provide dedicated resources to assist with critical support and surge allocations",},
          // {text: "",},
        ]
      },
    },
    {
      active: false,
      mdfy: "",
      pre: "3.",
      title: "Past Performance",
      paragraphs: {
        active: true,
        content: [
          {text: "DoJ, CMAS, Subcontract to GovTact. Contract value available upon request, supported from Aug 12, 2019 - Sep 10, 2021, DevOps infrastructure, Senior Engineers configured and managed virtual and Azure enclaves on both Class and un-Class environments. Contact: Luke Howe, COO, Luke.Howe@GovTact.com, (602)663-2068",},
          {text: "DoS, DS\CTO, Subcontract to CACI. Contract value available upon request, supported from Aug 20, 2016 - Aug 30, 2018, DevOps infrastructure, Team of Network Engineers, SysAdmin and Infrastructure Architects, designed, configured and managed Developmental virtual and Azure enclaves for Dev and Test. Contact: Tino Cascio, Project PM, CCascio@CACI.com, (301)213-0593",},
          {text: "Contracts to include: E&I- Cooperative, NY- State industrial & commercial, NYC - Janitorial,Plumbing and power tools, 8/5/2013 - 4/01/2019, partnered with city, state, higher education and health care entities with solution planning and execution around their industrial & commercial needs, while maintaining contract compliance. Also partnered with selected small businesses on relationship building, solution planning, securing opportunity and execution of desired industrial & commercial solutions, while maintaining contract compliance and rules of engagement. Assisted and trained selected small enterprise on comprehension of local and state, small and minority spend mandates specific to their SMBA certifications. ",},
          // {text: "",},
          // {text: "",},
          // {text: "",},
        ]
      },
    },
    {
      active: false,
      mdfy: "",
      pre: "4.",
      title: "Modern Urban Structures",
      paragraphs: {
        active: true,
        content: [
          {text: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.",},
        ]
      },
    },
    {
      active: false,
      mdfy: "",
      pre: "5.",
      title: "Modern Interior Structures",
      paragraphs: {
        active: true,
        content: [
          {text: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.",},
        ]
      },
    },
  ],
}