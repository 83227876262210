import React from "react";
import ReactDOM from "react-dom";
// import Handlebars from "handlebars";
const { data } = require('./Services.data');

import "./Services.scss";

// const htmlContent = require("./Services.html");
import htmlContent from './Services.hbs';// works as template fn

// const template = Handlebars.compile(htmlContent);// fails

const Services = (props) => {

  const {
    // data
  } = props;

  const h_cont = htmlContent(data);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: h_cont }} className={`hbs_comp plax-bg`} />
      {/* <div dangerouslySetInnerHTML={{ __html: template(data) }} /> */}
    </>
  );
}

export default Services
