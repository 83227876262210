import React, { useState } from "react";
import ReactDOM from "react-dom";
// import Handlebars from "handlebars";

import "./Faqs.scss";

// const htmlContent = require("./Sample.html");
import htmlContent from './Faqs.hbs';// works as template fn
import { randomizer } from "../../tools/randomizer";

// const template = Handlebars.compile(htmlContent);// fails

const Faq = (props) => {

  const {
    data, 
    backgrounds,
  } = props;

  const random_background = () => { 
    const rNbr = randomizer(backgrounds.length - 1, 0);

    if(1) console.log(`[faq] background nbr`, rNbr);
    return backgrounds[rNbr];
  }

  const [background, setbackground] = useState(random_background());

  data.background = background;

  const h_cont = htmlContent(data);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: h_cont }} />
      {/* <div dangerouslySetInnerHTML={{ __html: template(data) }} /> */}
    </>
  );
}

export default Faq
