

// const data_array = [{ title: "Home", href: "Home" }, { title: "About", href: "About" }, { title: "Projects", href: "Projects" }, { title: "Services", href: "Services" }, { title: "Blog", href: "Blog" }, { title: "Contact", href: "Contact" }];

// export const data = {
//   about: "About",
//   company: "ABDS",
//   paragraph1: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringian uatelit finibus viverra nec lacus.Nedana theme erodino setlie suscipe no curabit tristique.",
//   paragraph2: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringian uatelit finibus viverra nec lacus.Nedana theme erodino setlie suscipe no curabit tristique.",
//   paragraph3: "Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringian uatelit finibus viverra nec lacus.Nedana theme erodino setlie suscipe no curabit tristique.",
//   caption: "Canada Office",
//   img: "abds/lib/img/about.jpg",
//   menu: data_array,
// }// data

const nav_array = [{ title: "HOME", href: "HOME" }, { title: "ABOUT US", href: "ABOUT US" }, { title: "CAPABILITIES", href: "CAPABILITIES" }, { title: "CAREERS", href: "CAREERS" }, { title: "CONTACT US", href: "CONTACT US" }];

export const data = {
  about: "ABOUT",
  company: "US",
  // paragraph1: "ABDS exists to provide a solutions driven, trusted partnership with clients by understanding communicated needs, discovering vulnerabilities, and executing a creating a concise plan of action.",
  // paragraph2: "We bring extensive industry knowledge, foresight, and a passion for Technology.",
  // paragraph3: "Using a collaborative approach while focusing on delivering value.",
  paragraph1: "Since our inception, A Binary Data Solution has worked within the Public sector to provide mission critical support to our clients,  with an emphasis on need based solutions through analysis, to bring cost efficiency and overall true value.",
  paragraph2: "ABDS exists to provide a solutions driven, trusted partnership with clients by understanding communicated needs, discovering vulnerabilities, then creating and executing a concise plan of action.  We bring extensive industry knowledge, foresight, and a passion for Technology. ABDS uses a collaborative approach while focusing on delivering value.",
  // caption: "no office",
  // img: "https://images.pexels.com/photos/2582937/pexels-photo-2582937.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=200",
  img: "abds/lib/img/black-and-gray-motherboard.jpg",
  origin: "https://www.pexels.com/photo/black-and-gray-motherboard-2582937/",
  attrib: false,
  menu: nav_array,
}// data