import React from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
// import Handlebars from "handlebars";
const { data, back } = require('./Navbar.data');

import "./Navbar.scss";

// const htmlContent = require("./Navbar.html");
import htmlContent from './Navbar.hbs';// works as template fn


// const template = Handlebars.compile(htmlContent);// fails

const Navbar = (props) => {

  let location = useLocation();
  let history = useHistory();
  debugger;

  const {
    // data
  } = props;

  let use_data = location.pathname == "/" ? data : back;

  const h_cont = htmlContent(use_data);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: h_cont }} className={`hbs_comp plax-bg`} />
      {/* <div dangerouslySetInnerHTML={{ __html: template(data) }} /> */}
    </>
  );
}

export default Navbar
