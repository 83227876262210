import React from "react";
import ReactDOM from "react-dom";
// import Handlebars from "handlebars";

import "./Lines.scss";

// const htmlContent = require("./Sample.html");
import htmlContent from './Lines.hbs';// works as template fn

// const template = Handlebars.compile(htmlContent);// fails

const Lines = (props) => {

  const {
    data
  } = props;

  const h_cont = htmlContent(data);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: h_cont }} />
      {/* <div dangerouslySetInnerHTML={{ __html: template(data) }} /> */}
    </>
  );
}

export default Lines
